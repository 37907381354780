import '../styles/about-page.css';

export const AboutPage = () => {
    return (
        <section className='about-me-page' id='about-me'>
            <div className='about-me-info'>
                <div className='about-info-left'>
                    <h2>About me</h2>
                    <div id='no-display-container'>
                        <div className='circle' id='no-display'>
                            <img
                                className='profile-pic'
                                id='no-display-pic'
                                src={
                                    process.env.PUBLIC_URL +
                                    `/assets/images/lexy.jpg`
                                }
                                alt='profile pic'
                            />
                        </div>
                    </div>
                    <p>
                        Hi! I'm Alexa. I'm sipping coffee
                        <svg viewBox='0 0 319.068 319.068'>
                            <path
                                d='M319.068,252.071c-0.004-15.66-53.484-22.476-88.944-25.28c0.652-1.42,1.296-2.864,1.94-4.372
                                    c31.868-6.172,52.688-24.972,54.484-49.528c1.188-16.184-4.024-29.368-14.288-36.16c-7.352-4.868-16.384-5.7-24.68-2.46
                                    c-0.8-7.72-2.416-15.064-4.964-21.772c-0.004-3.248-0.224-12.944-53.46-16.8c-0.056-0.004-0.1,0.02-0.152,0.016
                                    c-0.024-4.46,2.048-9,5.86-12.344c0.748-0.66,1.556-1.28,2.364-1.9c2.296-1.764,4.672-3.584,6.092-6.324
                                    c1.26-2.432,1.432-5.012,1.444-6.984c0.04-7.052-2.22-14.2-6.368-20.132c-0.772-1.112-2.3-1.372-3.408-0.608
                                    c-1.108,0.78-1.376,2.304-0.604,3.412c3.572,5.112,5.52,11.248,5.484,17.296c-0.012,2.12-0.288,3.592-0.896,4.764
                                    c-0.916,1.768-2.768,3.192-4.724,4.696c-0.892,0.684-1.78,1.372-2.612,2.1c-5.32,4.668-8.144,11.264-7.556,17.648
                                    c0.632,6.832,5.24,12.98,11.744,15.652c0.304,0.132,0.62,0.188,0.932,0.188c0.96,0,1.872-0.572,2.264-1.516
                                    c0.512-1.252-0.08-2.684-1.332-3.196c-2.752-1.136-5.06-3.18-6.628-5.652c34.108,2.688,43.932,8.132,44.664,9.452
                                    c-2.02,4.14-31.26,11.536-82.344,11.536c-11.744,0-23.14-0.4-33.864-1.204c-1.896-0.144-3.576,1.292-3.72,3.204
                                    s1.292,3.58,3.2,3.72c10.9,0.812,22.464,1.224,34.384,1.224c3.108,0,65.868-0.164,84.208-11.396
                                    c8.532,28.52,0.584,69.468-10.816,97.684c-0.66,0.652-2.516,6.384-4.848,10.852c-0.188,0.26-0.328,0.536-0.44,0.844
                                    c-0.652,1.284-1.304,2.588-1.964,3.768c-8.008,14.348-13.272,16.296-14.696,16.552c-20.356,3.632-73.564,6.856-102.708,0.032
                                    c-7.464-1.74-15.196-9.644-21.784-22.248c-14.104-26.992-21.82-74.616-11.144-107.748c2.824,1.876,7.112,3.52,13.032,5.024
                                    c0.288,0.076,0.576,0.112,0.86,0.112c1.548,0,2.96-1.048,3.36-2.616c0.472-1.86-0.652-3.744-2.508-4.216
                                    c-8.724-2.22-11.232-4.196-11.876-4.892c1.248-1.396,7.428-4.748,25.3-7.512c1.896-0.292,3.192-2.064,2.9-3.96
                                    c-0.292-1.892-2.088-3.192-3.96-2.896c-23.488,3.628-29.584,8.38-30.96,12.236c-0.372,0.34-0.692,0.748-0.892,1.248
                                    c-14.104,35.468-6.128,88.072,9.736,118.436c7.644,14.632,16.756,23.552,26.356,25.796c12.904,3.016,30.336,4.136,47.68,4.136
                                    c23.044,0,45.932-1.98,57.824-4.1c6.172-1.1,12.748-7.828,19.54-19.996c0.412-0.74,0.828-1.588,1.24-2.372
                                    c64.32,4.836,85.304,14.552,85.304,18.56c0,7.316-52.408,20.964-152.592,20.964S6.936,259.395,6.936,252.075
                                    c0-1.956,8.444-8.792,45.96-14.336c1.896-0.28,3.204-2.044,2.928-3.94c-0.28-1.904-2.044-3.192-3.94-2.928
                                    C0,238.539,0,248.723,0,252.071c0,13.704,40.22,21.144,83.924,24.816c-0.216,0.376-0.372,0.788-0.36,1.252
                                    c0.18,6.736,5.716,12.216,12.4,12.352c2.356,0.456,20.716,3.68,64.884,3.68c40.724,0,62.724-3.676,62.532-3.676
                                    c6.796,0,12.468-5.54,12.648-12.356c0.012-0.48-0.156-0.908-0.388-1.292C279.148,273.159,319.068,265.723,319.068,252.071z
                                        M247.872,158.159c2.72-4.256,8.776-5.088,11.944-3.276c3.588,2.04,5.708,8.24,5.532,16.18
                                    c-0.164,7.68-4.004,12.896-7.192,15.924c-4.508,4.28-10.56,6.8-16.476,7.076C244.796,182.191,247.48,158.775,247.872,158.159z
                                        M239.696,201.075c0.024,0.004,0.04,0.016,0.06,0.016c0.288,0.008,0.58,0.016,0.872,0.016c7.984,0,16.268-3.36,22.304-9.084
                                    c5.86-5.56,9.18-12.948,9.352-20.804c0.24-10.816-3.056-18.964-9.04-22.368c-3.096-1.756-8.156-2.896-15.076,0.704
                                    c0.044-2.668,0.024-5.32-0.088-7.944c6.724-3.404,14.32-3.08,20.348,0.912c8.112,5.368,12.196,16.256,11.2,29.864
                                    c-1.48,20.164-18.336,35.964-44.492,42.284C236.748,210.355,238.284,205.819,239.696,201.075z M231.168,278.011
                                    c-0.108,4.204-3.604,7.624-8.196,7.656c-0.212,0.04-21.868,3.644-62.124,3.644c-45.856,0-63.968-3.592-64.144-3.628
                                    c-0.16-0.028-0.324-0.048-0.488-0.048c-4.184,0-7.68-3.42-7.792-7.624c-0.008-0.276-0.088-0.532-0.18-0.78
                                    c25.364,1.984,51.488,2.74,71.288,2.74c19.94,0,46.288-0.768,71.824-2.78C231.252,277.447,231.172,277.715,231.168,278.011z'
                            />
                            <path
                                d='M219.524,191.327c-2.288,6.78-5.176,13.256-8.66,19.504c-1.536,2.752,2.688,5.228,4.228,2.472
                                    c3.688-6.608,6.736-13.504,9.156-20.672C225.26,189.639,220.532,188.355,219.524,191.327z'
                            />
                            <path
                                d='M227.196,182.023c3.448-16.208,3.68-32.388,0.704-48.696c-0.564-3.096-5.284-1.784-4.724,1.304
                                    c2.82,15.444,2.564,30.736-0.704,46.088C221.82,183.791,226.54,185.107,227.196,182.023z'
                            />
                            <path
                                d='M264.668,256.247c-1.64,0.144-3.056,0.268-4.136,0.388c-1.336,0.148-2.296,1.348-2.148,2.684
                                    c0.136,1.244,1.188,2.16,2.412,2.16c0.088,0,0.176-0.004,0.268-0.016c1.052-0.116,2.432-0.24,4.028-0.376
                                    c12.404-1.08,23.852-2.392,27.668-6.424c1.972-2.084,1.692-4.036,1.352-5.016c-0.956-2.736-4.512-5.9-23.66-8.636
                                    c-1.328-0.164-2.56,0.732-2.752,2.06c-0.188,1.328,0.736,2.556,2.064,2.748c17.78,2.54,19.62,5.164,19.468,5.504
                                    C286.364,254.355,271.16,255.683,264.668,256.247z'
                            />
                            <path
                                d='M106.492,102.123c1.844,4.808,5.676,8.84,10.612,10.868c0.304,0.128,0.62,0.188,0.932,0.188
                                    c0.964,0,1.876-0.572,2.264-1.516c0.512-1.252-0.08-2.684-1.332-3.192c-2.632-1.084-4.856-3.008-6.42-5.336
                                    c7.992-0.772,16.592-1.332,25.684-1.632c1.916-0.06,3.416-1.664,3.352-3.58c-0.06-1.916-1.64-3.512-3.584-3.352
                                    c-9.852,0.32-19.16,0.94-27.744,1.812c-0.252-4.672,1.888-9.504,5.888-13.008c0.748-0.66,1.556-1.276,2.36-1.892
                                    c2.296-1.764,4.672-3.592,6.096-6.332c1.256-2.432,1.432-5.008,1.444-6.984c0.04-7.056-2.22-14.2-6.368-20.132
                                    c-0.768-1.112-2.296-1.376-3.412-0.608c-1.104,0.776-1.376,2.304-0.604,3.408c3.572,5.112,5.516,11.256,5.484,17.3
                                    c-0.012,2.116-0.288,3.592-0.896,4.764c-0.92,1.776-2.768,3.192-4.728,4.7c-0.892,0.684-1.78,1.368-2.608,2.092
                                    c-5.32,4.668-8.144,11.264-7.556,17.648C105.432,98.147,106.08,101.619,106.492,102.123z'
                            />
                            <path
                                d='M157.228,112.871c0.376,0.208,0.788,0.312,1.192,0.312c0.86,0,1.692-0.448,2.14-1.252c0.66-1.18,0.236-2.672-0.944-3.332
                                    c-2.64-1.48-4.884-4.116-6.488-7.34c0.092,0.004,0.156,0.024,0.256,0.024l3.372-0.004c6.852-0.004,12.248-0.016,18.916,0.332
                                    c0.06,0,0.12,0.004,0.18,0.004c1.836,0,3.368-1.44,3.464-3.296c0.1-1.912-1.372-3.544-3.288-3.644
                                    c-6.852-0.348-12.336-0.324-19.28-0.332c0,0-5.512-0.176-5.844-0.06c-0.12-0.74-0.224-1.484-0.272-2.24
                                    c-0.468-6.904,1.896-14.36,6.168-19.456c0.752-0.9,1.56-1.74,2.372-2.588c2.256-2.356,4.588-4.792,5.932-8.324
                                    c1.16-3.052,1.32-6.336,1.332-8.856c0.04-9.296-2.16-18.732-6.192-26.584c-0.62-1.208-2.096-1.692-3.296-1.06
                                    c-1.204,0.616-1.676,2.096-1.06,3.296c3.68,7.164,5.688,15.804,5.652,24.328c-0.008,2.104-0.132,4.82-1.012,7.132
                                    c-0.992,2.604-2.888,4.58-4.892,6.68c-0.884,0.924-1.768,1.844-2.588,2.824c-5.128,6.12-7.856,14.696-7.3,22.936
                                    C146.352,101.259,150.856,109.307,157.228,112.871z'
                            />
                        </svg>
                        with my nose stuck on a book
                        <svg viewBox='0 0 512.004 512.004'>
                            <path
                                d='M291.057,242.811c1.51,2.953,4.514,4.659,7.62,4.659c1.297,0,2.628-0.299,3.866-0.93
                                    c0.503-0.256,50.731-25.771,75.503-33.596c4.489-1.425,6.98-6.221,5.555-10.709c-1.417-4.489-6.178-6.989-10.709-5.572
                                    c-26.095,8.252-75.981,33.596-78.097,34.671C290.596,233.467,288.924,238.605,291.057,242.811z'
                            />
                            <path
                                d='M298.677,145.071c1.297,0,2.628-0.299,3.866-0.93c0.503-0.256,50.731-25.771,75.503-33.596
                                    c4.489-1.425,6.98-6.221,5.555-10.709c-1.417-4.489-6.178-6.989-10.709-5.572c-26.095,8.252-75.981,33.596-78.097,34.671
                                    c-4.198,2.133-5.871,7.27-3.738,11.477C292.567,143.364,295.571,145.071,298.677,145.071z'
                            />
                            <path
                                d='M503.469,128.004c-4.719,0-8.533,3.823-8.533,8.533v332.8c0,14.114-11.486,25.6-25.6,25.6h-204.8v-19.635
                                    c12.442-4.352,44.851-14.498,76.8-14.498c74.334,0,124.809,16.461,125.312,16.631c2.568,0.853,5.436,0.427,7.68-1.178
                                    c2.227-1.604,3.541-4.181,3.541-6.921V93.871c0-4.002-2.773-7.467-6.682-8.329c0,0-6.69-1.493-18.125-3.593
                                    c-4.617-0.853-9.079,2.219-9.933,6.844c-0.853,4.642,2.21,9.088,6.844,9.941c4.361,0.802,8.013,1.51,10.829,2.074v357.188
                                    c-19.337-5.069-62.276-14.259-119.467-14.259c-37.18,0-73.702,12.211-85.001,16.35c-10.044-4.437-40.405-16.35-77.133-16.35
                                    c-58.778,0-107.196,9.694-128,14.618V100.475c17.041-4.19,67.371-15.138,128-15.138c31.113,0,57.796,9.685,68.267,14.063v335.804
                                    c0,3.072,1.655,5.914,4.326,7.424c2.671,1.519,5.965,1.476,8.602-0.111c0.845-0.503,85.393-51.004,160.435-76.015
                                    c3.49-1.169,5.837-4.42,5.837-8.098V8.537c0-2.799-1.374-5.419-3.678-7.014c-2.287-1.596-5.222-1.963-7.859-0.981
                                    C346.856,26.15,277.771,69.141,277.079,69.568c-3.994,2.5-5.214,7.765-2.714,11.759c2.492,3.994,7.757,5.214,11.759,2.714
                                    c0.631-0.401,60.732-37.794,123.477-63.027v331.281c-58.249,20.241-119.066,53.291-145.067,68.087V93.871
                                    c0-3.234-1.826-6.187-4.719-7.637c-1.468-0.725-36.437-17.963-80.614-17.963c-77.107,0-136.388,16.683-138.88,17.399
                                    c-3.661,1.041-6.187,4.395-6.187,8.201v375.467c0,2.671,1.263,5.197,3.388,6.81c1.502,1.135,3.311,1.724,5.146,1.724
                                    c0.785,0,1.57-0.111,2.338-0.333c0.589-0.162,59.597-16.734,134.195-16.734c31.198,0,57.856,9.711,68.267,14.071v20.062h-204.8
                                    c-14.114,0-25.6-11.486-25.6-25.6v-332.8c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v332.8
                                    c0,23.526,19.14,42.667,42.667,42.667h426.667c23.526,0,42.667-19.14,42.667-42.667v-332.8
                                    C512.002,131.827,508.188,128.004,503.469,128.004z'
                            />
                            <path
                                d='M291.057,191.611c1.51,2.953,4.514,4.659,7.62,4.659c1.297,0,2.628-0.299,3.866-0.93
                                    c0.503-0.256,50.731-25.771,75.503-33.596c4.489-1.425,6.98-6.221,5.555-10.709c-1.417-4.489-6.178-6.989-10.709-5.572
                                    c-26.095,8.252-75.981,33.596-78.097,34.671C290.596,182.267,288.924,187.405,291.057,191.611z'
                            />
                            <path
                                d='M291.057,294.011c1.51,2.953,4.514,4.659,7.62,4.659c1.297,0,2.628-0.299,3.866-0.93
                                    c0.503-0.256,50.731-25.771,75.503-33.596c4.489-1.425,6.98-6.221,5.555-10.709c-1.417-4.489-6.178-6.989-10.709-5.572
                                    c-26.095,8.252-75.981,33.596-78.097,34.671C290.596,284.667,288.924,289.805,291.057,294.011z'
                            />
                            <path
                                d='M206.748,158.366c-52.693-12.365-112.572,3.388-115.089,4.062c-4.548,1.22-7.253,5.896-6.033,10.453
                                    c1.024,3.814,4.471,6.323,8.235,6.323c0.734,0,1.476-0.094,2.219-0.29c0.572-0.162,58.223-15.326,106.778-3.934
                                    c4.565,1.067,9.182-1.775,10.257-6.366C214.189,164.032,211.339,159.441,206.748,158.366z'
                            />
                            <path
                                d='M206.748,209.566c-52.693-12.356-112.572,3.388-115.089,4.062c-4.548,1.22-7.253,5.897-6.033,10.453
                                    c1.024,3.814,4.471,6.323,8.235,6.323c0.734,0,1.476-0.094,2.219-0.29c0.572-0.162,58.223-15.326,106.778-3.934
                                    c4.565,1.067,9.182-1.775,10.257-6.366C214.189,215.232,211.339,210.641,206.748,209.566z'
                            />
                            <path
                                d='M291.057,345.211c1.51,2.953,4.514,4.659,7.62,4.659c1.297,0,2.628-0.299,3.866-0.93
                                    c0.503-0.256,50.731-25.771,75.503-33.596c4.489-1.425,6.98-6.221,5.555-10.709c-1.417-4.488-6.178-6.989-10.709-5.572
                                    c-26.095,8.252-75.981,33.596-78.097,34.671C290.596,335.867,288.924,341.005,291.057,345.211z'
                            />
                            <path
                                d='M206.748,260.766c-52.693-12.356-112.572,3.379-115.089,4.062c-4.548,1.22-7.253,5.897-6.033,10.453
                                    c1.024,3.814,4.471,6.332,8.235,6.332c0.734,0,1.476-0.102,2.219-0.299c0.572-0.162,58.223-15.326,106.778-3.934
                                    c4.565,1.067,9.182-1.775,10.257-6.366C214.189,266.432,211.339,261.841,206.748,260.766z'
                            />
                            <path
                                d='M206.748,363.166c-52.693-12.365-112.572,3.388-115.089,4.062c-4.548,1.22-7.253,5.897-6.033,10.453
                                    c1.024,3.814,4.471,6.332,8.235,6.332c0.734,0,1.476-0.102,2.219-0.299c0.572-0.162,58.223-15.326,106.778-3.934
                                    c4.565,1.058,9.182-1.775,10.257-6.366C214.189,368.832,211.339,364.241,206.748,363.166z'
                            />
                            <path
                                d='M206.748,311.966c-52.693-12.365-112.572,3.379-115.089,4.062c-4.548,1.22-7.253,5.897-6.033,10.453
                                    c1.024,3.814,4.471,6.332,8.235,6.332c0.734,0,1.476-0.102,2.219-0.299c0.572-0.162,58.223-15.326,106.778-3.934
                                    c4.565,1.067,9.182-1.775,10.257-6.366C214.189,317.632,211.339,313.041,206.748,311.966z'
                            />
                        </svg>
                        99% of my free time.
                    </p>
                    <p>
                        I speak English and Spanish on a native level and German on an intermediate
                        level.</p>
                    <p>
                        Graduated from a 6-months long intensive Bootcamp and
                        looking for new opportunities.
                    </p>
                    <p>I'm currently located in Graubünden, Switzerland.</p>
                    <div className='tech-lists-container'>
                        <p>Technologies I'm currently working with:</p>
                        <div className='tech-lists'>
                            <ul>
                                <li>HTML</li>
                                <li>CSS</li>
                                <li>JavaScript(ES6)</li>
                                <li>React.js</li>
                                <li>Node.js</li>
                            </ul>
                            <ul>
                                <li>Prisma</li>
                                <li>Express.js</li>
                                <li>PostgreSQL</li>
                                <li>TypeScript</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='about-info-right'>
                    <div className='circle' id='display'>
                        <img
                            className='profile-pic'
                            id='display-pic'
                            src={
                                process.env.PUBLIC_URL +
                                `/assets/images/lexy.jpg`
                            }
                            alt='profile pic'
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};